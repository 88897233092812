* {
    -webkit-font-smoothing: antialiased;
}

html {
    background: #232323;
}

body {
    padding: 0;
    margin: 0;
}

.windowContainer {
    min-height: 100vh;
    background: #232323;
}

.mainContainer {
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    margin-bottom: 48px;
    max-width: 536px;
}

@media (max-width: 530px ) {
    .mainHeader {
        content: "";
        color: wheat;
        margin: 2em 0;
    }
}

@media (min-width: 531px) {
    .mainHeader {
        content: "";
        color: brown;
        margin: 2em 0;
    }
}

.payerContainer {
    border-radius: 0.7em;
    border: 1px solid transparent;
    background-color: rgb(18, 18, 22);
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    justify-self: center;
    flex-direction: column;
    align-items: center;
    min-height: 120px;
}

.payerContent {
    width: 100%;
}

.payerHeader {
    width: 100%;
    padding-top: 16px;
    padding-left: 40px;
    border-bottom: 1px solid #353535;
    padding-bottom: 16px;
}

.payerQRCodeContainer {
    padding-top: 66px;
    height: 10rem;
    /* margin-bottom: 40px; */
    display: flex;
    align-items: center;
    margin: auto;
    width: 100%;
    align-self: center;
    background-color: #353535;
    justify-content: center;
}

.payerQRCode {
    background: aliceblue;
    padding: 0.6em;
    border-radius: 0.5em;
}

.payerAddressContainer {
    margin-top: 66px;
}

.text {
    margin: 0px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.005rem;
    text-align: left;
}

.label {
    color: rgba(255, 255, 255, 0.40);
    font-size: 0.75rem;
    margin-top: 16px;
    font-weight: 400;
    line-height: 1.5rem;
    margin-left: 0.1875rem;
    margin-bottom: 8px;
}

.dataContainer {
    margin: 0 1em;
}

input::placeholder {
    color: #fff !important;
}

.copy-button {
    border: 1px solid #ced4da !important;
    border-left: none !important;
}

.form-control {
    background-color: #000 !important;
}

.addrInput {
    border-right: none !important;
}

.copy-button:hover {
    background-color: #000 !important;
}

.btn {
    background-color: #000 !important;
}

.crypto-button {
    padding: 0 0.4em !important;
    border: 1px solid #ced4da !important;
    border-right: none !important;
}

.crypto-input {
    border-left: none;
}

.addText {
    color: rgba(255, 255, 255, 0.68);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5rem;
    padding-bottom: 8px;
}

.jss50 {
    color: rgb(249, 185, 91);
    background-color: rgba(249, 185, 91, 0.1);
}

.jss47 {
    padding: 12px 16px;
    border-radius: 0.5rem;
    flex-direction: row-reverse;
}

.css-jl6tkp {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    background-image: none;
    font-family: Inter, sans-serif;
    font-weight: 500;
    letter-spacing: 0.005rem;
    background-color: rgb(24, 19, 12);
    display: flex;
    padding: 6px 16px;
    font-size: 0.75rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    flex-direction: row-reverse;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(249, 185, 91);
    margin-top: 24px;
    margin-bottom: 16px;
}

.css-jl6tkp .MuiAlert-icon {
    color: rgb(249, 185, 91);
}

.jss49 {
    padding: 0px;
    align-items: center;
    margin-left: 16px;
    margin-right: 0px;
}

.css-46m66y {
    padding: 7px 0px;
    display: flex;
    font-size: 22px;
    opacity: 0.9;
    margin-right: 16px;
    margin-left: 0px;
    -webkit-box-align: center;
    align-items: center;
}

.jss48 {
    flex: 1 1 0%;
    padding: 0px;
    font-size: 0px;
    line-height: 0;
}

.css-2gmorj {
    margin: 0px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.01rem;
    text-align: left;
}

.jss50 svg {
    fill: rgb(249, 185, 91);
}

.css-1cw4hi4 {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: inherit;
}

.jss34 {
    border-top: 1px solid #353535;
    margin-top: 32px;
    padding: 8px 40px 16px;
}

.css-1d3bbye {
    width: 100%;
}

.form-select {
    background-color: #000000 !important;
    color: #ffffff !important;
}

select:hover {
    border-color: #1E90FFFF !important;
}

.input-group:hover * {
    border-color: #1E90FFFF !important;
}

/*POPUP*/
.popup-content {
    width: 25% !important;
}

.popup-header {
    display: none !important;
}

.popup-body:last-child {
    margin-bottom: 0 !important;
}

.popup-body p {
    font-size: large !important;
}

.popup.popup .popup-content {
    border: 0.5px solid white !important;
}